.specific-agreement-alteration__container {
  background-color: white;
  margin-bottom: 20px;
}

.specific-agreement-alteration__container
  .specific-agreement-alteration__form-title-container
  .fa {
  margin-right: 10px;
}

.specific-agreement-alteration__container
  .specific-agreement-alteration__form-title-container {
  display: flex;
  justify-content: space-between;
}

.specific-agreement-alteration__container
  .specific-agreement-alteration__form-title-container
  .specific-agreement-alteration__alteration-badge {
  max-width: 120px;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
}

.specific-agreement-alteration__container
  .specific-agreement-alteration__buttons-container
  > button {
  text-align: left;
}

.specific-agreement-alteration__container
  .specific-agreement-alteration__buttons-container
  > button
  span.fa {
  margin-right: 10px;
}

.specific-agreement-alteration__modal {
  width: 500px;
  background-color: white;
  margin: 0 auto;
}

.specific-agreement-alteration__modal textarea {
  resize: vertical;
}

.specific-agreement-alteration__modal
  .specific-agreement-alteration__modal-extension-content {
  margin-top: 10px;
}

.specific-agreement-alteration__modal
  .specific-agreement-alteration__modal-extension-content,
.specific-agreement-alteration__modal
  .specific-agreement-alteration__modal-extension-content
  label {
  font-size: 15px;
}

.specific-agreement-alteration__modal
  .specific-agreement-alteration__icon-title {
  margin-right: 10px;
}

.specific-agreement-alteration__description-container {
  font-size: 15px;
}

.specific-agreement-alteration__description-container
  .specific-agreement-alteration__description-label {
  font-weight: bold;
}
