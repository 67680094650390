.list-container {
  position: relative;
}

.list-container.loading {
  cursor: wait;
}

.list-container.loading * {
  pointer-events: none;
}

.list-container.loading:after {
  content: '';
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;
  position: absolute;
  inset: 0;
  background-color: rgb(255 255 255 / 83%);
  backdrop-filter: blur(1px);
  font-size: 18px;
  font-weight: 600;
  z-index: 10;
}

.list-container .sk-spinner {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: min(60%, 10vh);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  transition: opacity 0.2s
}

.list-container.loading .sk-spinner {
  opacity: 1;
}

.list-container.loading .pagination {
  position: relative;
  z-index: 11;
}
