.agreement-printing-template__container
  .agreement-printing-template__action-buttons-container,
.agreement-printing-template__container
  .agreement-printing-template__frame-container {
  margin: 10px 0px;
}

.agreement-printing-template__frame-container iframe {
  width: 100%;
  border: none;
  height: 600px;
}
