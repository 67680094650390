.school-form-select__item-container {
  display: inline-block;
  padding: 6px;
  cursor: pointer;
  margin: 0px 10px;
}

.school-form-select__item-container:first-child {
  margin-left: 0px;
}

.school-form-select__item-container:last-child {
  margin-right: 0px;
}

.school-form-select__item-container.selected {
  outline: 1px solid red;
}

.school-form-select__item-container .school-form-select__short-name {
  font-size: 14px;
  text-align: center;
}
