.header-bar {
  background-color: white;
  margin-bottom: 0;
  height: 60px;
  position: relative !important;
}

.header-bar .container-fluid {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.header-bar .navbar-header {
  float: none;
}

.header-bar .header-bar-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
}

.header-bar .header-bar-title > div {
  display: inherit;
}

.header-bar .header-bar-avatar {
  padding: 10px 0px;
}

.header-bar .header-bar-avatar {
}

.header-bar .header-bar__autocomplete-container {
  width: 100%;
}

.header-bar .header-bar__filters-trigger {
  align-self: center;
  font-size: 1.4rem;
}

.header-bar .header-bar__filters-trigger.is-expanded {
  border-color: #073129;
}

.header-bar .header-bar__filters-trigger .fa:first-child {
  margin-right: 0.6rem;
}

.header-bar .header-bar__filters-trigger .badge {
  margin-left: 0.6rem;
  background: white;
  border-color: white;
  color: #1ab394;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: bold;
}

.header-bar .header-bar-logout a {
  color: #676a6c;
}

.header-bar .navbar-right.header-bar-avatar {
  float: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  padding-right: 30px;
}
