.student-completion-form__container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.student-completion-form__form-container {
  background-color: white;
}

.student-completion-form__container .student-completion-form__error {
  text-align: center;
}
