.search-bar__form-input-wrapper {
  width: 100%;
}

.search-bar__form-input-wrapper .search-bar__form-input-container {
  margin: 0;
}

.search-bar__item-container {
  background-color: white;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  cursor: pointer;
}

.search-bar__item-container:hover {
  background-color: #e7e7e7;
}

.search-bar__item-container .search-bar__item-icon-container,
.search-bar__item-container .search-bar__item-data {
  display: inline-block;
  height: 100%;
  position: relative;
}

.search-bar__item-container .search-bar__item-data {
  margin-left: 10px;
}

.search-bar__item-container .search-bar__item-data > p {
  font-size: 14px;
  margin: 2px 0px;
  line-height: 1;
}

.search-bar__item-container
  .search-bar__item-data
  .search-bar__item-data-title {
  font-weight: bold;
}

.search-bar__item-container
  .search-bar__item-data
  .search-bar__item-data-subtitle {
  font-weight: 300;
}
