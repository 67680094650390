.form-group.has-error .react-tel-input .form-control,
.form-group.has-error .react-tel-input .flag-dropdown {
  border-color: #ed5565;
}

.form-group.has-error .react-tel-input .flag-dropdown {
  border-right: none;
}

.react-tel-input {
  width: 100%;
}

.react-tel-input input[type='text'], .react-tel-input input[type='tel'] {
  height: 37px;
  box-shadow: none;
  padding-left: 50px;
}

.react-tel-input input[disabled] {
  background: #eee;
}

.react-tel-input .selected-flag {
  display: flex;
  align-items: center;
  height: 35px;
}

.react-tel-input .selected-flag .flag {
  position: relative;
  top: 0;
  margin-top: 0;
}

.react-tel-input .country-list {
  box-shadow: none;
  top: 37px;
  border-top: none;
}