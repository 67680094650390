#page-wrapper {
  width: calc(100% - 220px);
  height: 100vh;
  margin: 0;
  padding: 0;
}

#main-container {
  height: calc(100% - 60px);
  overflow: hidden;
  display: flex;
  flex: 1;
}

#main-container > div {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-top: 30px;
}

#wrapper {
  display: flex;
}
