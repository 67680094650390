.student-card__container {
  background-color: white;
  padding: 12px 10px;
}

.student-card__container .student-card__list-placeholder {
  font-style: italic;
  color: #777;
}

.student-card__container .title {
  margin: 5px 0px;
}

.student-card__container .student-card__history ul {
  margin: 0;
  padding-left: 18px;
  font-weight: bold;
}