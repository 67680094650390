body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f3f3f4;
}

* {
  font-family: "Open Sans";
}

.float-none {
  float: none;
}

.inline-block {
  display: inline-block;
}

label {
  font-weight: 500;
  color: #676a6c;
}

.badge.outline {
  background-color: transparent;
}

.badge.badge-success.outline {
  border: 1px solid #1c84c6;
  color: #1c84c6;
}

.badge.badge-primary.outline {
  border: 1px solid #29b294;
  color: #29b294;
}

.badge.badge-default.outline {
  border: 1px solid #d2dade;
  color: #d2dade;
}

.badge.badge-danger.outline {
  border: 1px solid #ed5565;
  color: #ed5565;
}

/* Styles for IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    background-color: white;
  }

  .ie-container {
    text-align: center;
    width: 100%;
  }

  .ie-title {
    font-size: 16px;
    font-weight: bold;
  }

  .ie-description {
    font-size: 14px;
  }
}
