.modal__title-container {
  background-color: #f9fafa;
  height: 50px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.modal__title-container .modal__title {
  margin: 0;
}

.modal__content-container {
  background-color: white;
}

.modal__content-footer,
.modal__content-footer > * {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.modal__container {
  padding: 10px 0;
  padding-top: 0;
}

.modal__container:focus {
  outline: none;
}

.modal__overlay {
  background-color: rgba(159, 159, 160, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  flex: 1;
  align-items: center;
}
