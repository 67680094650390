.agreement-form__navigator-container {
  margin: 10px 0px 0px 0px;
  display: inline-block;
}

.agreement-form__navigator-container .agreement-form__button-group-container {
  display: flex;
}

.agreement-form__navigator-container .agreement-form__navigator-button {
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex: 1;
  color: #e5e5e5;
}

.agreement-form__navigator-container
  .agreement-form__navigator-button.agreement-form__navigator-button-next:hover {
  color: #7e7e7e;
}

.agreement-form__navigator-container
  .agreement-form__navigator-button-unselected {
  opacity: 0.75;
}

.agreement-form__step-container {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.agreement-form__approval-container,
.agreement-form__recruiter-directions-container {
  margin-top: 20px;
}

.agreement-form__recruiter-directions-content {
  background-color: white;
}

.agreement-form__step-container .agreement-form__printing-template-container {
  display: flex;
  align-items: center;
}

.agreement-form__step-container
  .agreement-form__printing-template-container
  button {
  margin-top: 7px;
}

.agreement-form__action-buttons {
  float: right;
  margin-top: 20px;
}

.specific-agreement__activity-rejected {
  width: 29px;
  padding-left: 8px !important;
}

.agreement-form__container .agreement-form__header-container {
  z-index: 2;
}

.agreement-form__container
  .agreement-form__header-container
  .agreement-form__header-card:not(:last-child) {
  margin-bottom: 3px;
}

.agreement-form__container .agreement-form__header-container {
  margin-bottom: 10px;
}
