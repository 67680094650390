.specific-agreement-status-card__container {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 4px 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

.specific-agreement-status-card__container .specific-agreement-status-card__title {
  margin: 5px 0;
}


.specific-agreement-status__container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}