.signin-container {
  padding: 0 40px;
  margin-top: 10px;
}

.signin-container input::placeholder {
  font-size: 16px;
}

.signin__reset-password {
  margin-top: 23px;
}

.signin__reset-password,
.descriptor {
  font-size: 16px;
  display: block;
}

.descriptor {
  color: #898989;
  margin-top: 24px;
}

.signin__signup-button {
  margin-top: 11px;
  color: #39393a;
}
