.topbar__container {
  padding: 20px 10px;
  background-color: white;
  display: flex;
  align-items: center;
}

.topbar__container .topbar__new-internship-btn {
  float: right;
  padding: 0 30px;
}

.topbar__container .topbar__title {
  margin: 0;
}

.topbar__container .topbar__subtitle {
  font-size: 15px;
}
