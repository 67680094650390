.form-group__header {
  padding: 20px;
  font-size: 15px;
  line-height: 20px;
  color: #39393a;
}

.form-group__header.form-group__header-bordered {
  border-bottom: 1px solid #f3f4f5;
}

.form-group__form-content {
  margin: 20px 0;
}
