.general-agreement-form__container .general-agreement-form__form {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.general-agreement-form__container .agreement-card__container {
  margin-bottom: 10px;
}

.general-agreement__action-buttons {
  float: right;
}
