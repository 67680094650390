.signup-container {
  text-align: center;
  padding: 0 40px;
  margin-top: 10px;
}

.signup-container input::placeholder {
  font-size: 16px;
}

.signup-checkbox-agreement {
  margin: 18px 0px;
}

.signup-checkbox-agreement span a {
  font-size: 16px;
  text-decoration: underline;
  color: #39393a;
}

.signup-container .descriptor {
  color: #898989;
  line-height: 26px;
  margin-top: 31px;
}

.signup-container .signup__signin-button {
  margin-top: 11px;
  color: #39393a;
}
