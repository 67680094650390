.comment-panel {
  border: none;
}

.comment-panel .panel-heading {
  background-color: #fff;
  padding: 0;
}
.comment-panel .panel-title a {
  display: block;
  font-size: 16px;
  padding: 20px;
}
.comment-panel .panel-body {
  color: rgb(135, 141, 150);
  padding: 0 40px;
}
.comment-panel .form-input {
  width: 100%;
}
.comment-panel .form-input-container:not(.with-label) {
  margin: 0 !important;
  width: 90%;
}

.comment-content {
  padding: 20px 0;
  color: #222222;
}
.comment-content {
  border-bottom: 1px solid #f3f3f4;
}
.comment-content .btn-delete {
  cursor: pointer;
}
.comment-content .comment-author {
  font-weight: 600;
}

.comment-content .comment-timeago {
  color: #898989;
}

.form-send-comment {
  padding: 20px 0;
}
.form-send-comment input {
  border: none;
  color: #999;
}
.form-send-comment button[type="submit"] {
  border: 0;
  max-width: 10%;
  height: auto;
  padding: 0;
}
.form-send-comment button[type="submit"]:hover {
  background: none;
}
.form-send-comment button .fa {
  background-color: #567de5;
  border-radius: 50%;
  color: #fff;
  padding: 14px;
}
