nav.navbar-default.sidebar-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 220px;
}

nav.navbar-default .fixed-sidebar {
  width: auto;
  position: relative;
  flex: 1;
}

nav.navbar-default .nav-header {
  background-color: #e45356;
  background-image: none;
  padding: 17px 18px 10px 18px;
}
