.approval-autocomplete__item-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
}

.approval-autocomplete__item-container:hover {
  background-color: #e7e7e7;
}

.approval__container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.approval-selected-user__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}

.approval-selected-user__container .approval-selected-user__info {
  display: inherit;
  align-items: center;
}

.approval-selected-user__container .approval-selected-user__remove {
  cursor: pointer;
}

.approval-main-info__full-name {
  margin-left: 10px;
  font-size: 16px;
}

.approval__container .approval__status-button {
  pointer-events: none;
}

.approval__container .approval__status-button:active,
.approval__container .approval__status-button:focus {
  background-color: inherit;
}

.approval__container .approval__status-button.btn-danger:active,
.approval__container .approval__status-button.btn-danger:focus {
  color: #ed5565;
}

.approval__container .approval__status-button.btn-primary:active,
.approval__container .approval__status-button.btn-primary:focus {
  color: #1ab394;
}

.approval__status-button .approval__icon {
  margin-right: 5px;
}

.approval__status-button .approval__icon-success {
  color: #1ab394;
}

.approval-selection-form__container {
  background-color: #fff;
}

.approval-selection-form__container .approval-selection-form__approve-button {
  margin-top: 20px;
}

.approval-selection-form__container .approval-autocomplete__form-input {
  margin: 0px;
  vertical-align: middle;
  border: none;
  outline: none;
}

.approval-selection-form__container
  .approval-autocomplete__form-input
  .approval-autocomplete__approval-avatar {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.approval-selection-form__container
  .approval-autocomplete__form-input
  .approval-autocomplete__approval-avatar
  + input {
  padding-left: 40px;
}

.approval-selection-form__skip-validation {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 0 0 0;
  text-decoration: underline;
  text-align: center;
}

.approval-status__container .approval-status__status-text {
  margin-left: 5px;
}

.approval-status__container .approval-status__cancel-btn {
  margin-top: 20px;
}

.approval-status__container .stacked-avatars__container {
  height: 40px;
  display: inline-block;
}

.approval-status__container .approval-status__names-container {
  display: flex;
  align-items: center;
}

.approval-cancel-button {
  background-color: #676b76;
  color: #fff;
}

.approval-cancel-button:hover {
  background-color: #808592;
  color: #fff;
}

.approval-status__school-admin-action-buttons {
  display: flex;
  margin-top: 20px;
}

.approval-status__school-admin-action-buttons button {
  flex: 1;
}

.approval__action-buttons-container {
  margin-top: 20px;
}

.approval-autocomplete__tags-input-container {
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  line-height: 28px;
  cursor: text;
  width: 100%;
}

.approval-autocomplete__tags-input-container
  .approval-autocomplete__tag-container {
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
  margin-right: 5px;
}

.approval-autocomplete__tags-input-container .react-tagsinput-remove {
  vertical-align: middle;
  margin-left: 5px;
}

.approval-autocomplete__tags-input-container .approval-autocomplete__full-name {
  margin-left: 5px;
  vertical-align: middle;
}
