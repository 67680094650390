.admin-schools-form__form-container {
  background-color: white;
}

.admin-schools-form__form-container .radio-inline > input {
  margin-top: 2px;
}

.admin-schools-form__radio-group-label {
  display: block;
}

.admin-schools-form__templates-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.admin-schools-form__templates-form-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.admin-schools-form__templates-form-container
  .admin-schools-form__templates-form-main-infos {
  display: flex;
  flex-direction: column;
}

.admin-schools-form__templates-form-container > button {
  align-self: center;
}

.admin-schools-api-keys__container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.admin-schools-api-keys__list-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
}

.admin-schools-api-keys__list-title {
  font-weight: bold;
}

.admin-schools-api-keys__list-container > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.admin-schools__e-signature-checkbox {
  margin: 18px 0px;
}
