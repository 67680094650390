.sidebar-list-item {
  color: #878d96;
  font-size: 14px;
  position: relative;
  margin: 7px 0px;
}

.sidebar-list-item .selected {
  font-weight: 700;
}

.sidebar-list-item a:not(.selected) {
  color: inherit;
}

.sidebar-list-item .sidebar-list-item-label {
  float: left;
  max-width: 90%;
}

.sidebar-list-item .sidebar-list-item-number {
  float: right;
}
