.specific-agreement-assignee-card__container {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px 10px;
  font-size: 16px;
  justify-content: flex-end;
}

.specific-agreement-assignee-card__container .user-item__container {
  margin-left: 10px;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-main-container {
  display: flex;
  flex: 1;
  margin-left: 10px;
  max-width: 350px;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-container
  .fa-times {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-input,
.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-input
  .input-wrapper {
  display: inherit;
  flex: 1;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-input
  .input-wrapper
  .form-input-icon.left {
  left: 7px;
  width: 25px;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-container
  input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.specific-agreement-assignee-card__container
  .specific-agreement-assignee-card__autocomplete-container
  button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  vertical-align: unset;
}

.specific-agreement-assignee-card__edit-button {
  background-color: #676b76;
  color: #fff;
  margin-left: 10px;
}

.specific-agreement-assignee-card__edit-button:hover {
  background-color: #808592;
  color: #fff;
}

.specific-agreement-assignee-card__container
  .autocomplete-user__container
  .user-item__description
  .user-item__position {
  font-size: 14px;
}
