.agreements-filters-drawer {
  background-color: white;
  padding: 1.6rem 1.6rem 0.4rem;
  transform: translateY(0);
  transition: all 0.2s;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.agreements-filters-drawer.is-open {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.agreements-filters-drawer.is-close {
  transform: translateY(-100%);
}

.agreements-filters-row .generic-filter-container {
  margin-bottom: 1.2rem
}

.agreements-filters-row .DayPickerInput {
  margin-block: -2rem;
}

.agreements-filters-clear {
 float: right
}

.agreements-filters-clear .fa {
  margin-right: 0.8rem
}
