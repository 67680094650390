.user-item__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
}

.user-item__description {
  margin-left: 5px;
}

.user-item__position {
  font-style: italic;
}
