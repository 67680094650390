.activity-form__radio-label {
  display: block;
}

.activity-form__radio-title {
  font-size: 17px;
}

.activity-form__radio-label ~ label.form-input {
  height: auto;
}
