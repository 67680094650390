.stacked-avatars__container {
  position: relative;
}

.stacked-avatars__container .stacked-avatars__item {
  position: absolute;
  top: 0;
}

.stacked-avatars__container .stacked-avatars__item > .stacked-avatars__item {
  z-index: 1;
  transform: translateX(75%);
}
