.agreements-list-container {
  background-color: white;
  margin-top: 30px;
  padding: 0 20px;
}

.agreements-list-container .agreements-list-table {
  padding: 0px 10px;
}

.agreements-list-container .agreements-list-table .agreements-list-row:hover {
  cursor: pointer;
}

.agreements-list-container .agreements-list-table .agreements-list-row td {
  vertical-align: middle;
}

.agreements-list-container .agreements-list-table .agreements-list-row td .label {
  display: inline-block;
}

.agreements-list-container
  .agreements-list-table
  .agreements-list-table-header {
  height: 50px;
}

.agreements-list-container
  .agreements-list-table
  .agreements-list-table-header
  .agreements-list-table-title {
  font-size: 15px;
  vertical-align: middle;
}
