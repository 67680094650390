.electronic-signature__container {
  margin-bottom: 20px;
}

.electronic-signature__form-container {
  background-color: white;
}

.electronic-signature__skip-validation {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 0 0 0;
  text-decoration: underline;
  text-align: center;
}

.electronic-signature__signatory-container
  .electronic-signature__data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.electronic-signature__signatory-container
  .electronic-signature__data-container
  .electronic-signature__data-value {
  font-size: 16px;
}

.electronic-signature__signatory-container
  .electronic-signature__data-container
  .electronic-signature__data-status {
  font-size: 14px;
}

.electronic-signature__signatory-container
  .electronic-signature__data-container
  .electronic-signature__data-status
  > .fa {
  margin-left: 5px;
}

.electronic-signature__modal {
  width: 500px;
  background-color: white;
  margin: 0 auto;
}

.electronic-signature__modal .electronic-signature__modal-content {
  margin-top: 10px;
}

.electronic-signature__form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.electronic-signature__send-reminder-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
