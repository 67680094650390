.file-upload {
  margin-bottom: 10px;
}

.file-upload .file-container {
  border: 1px solid #d1d1d1;
  background-color: #fafafa;
  height: 128px;
  width: 128px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.file-upload .remove-icon {
  position: relative;
  top: 2px;
  left: 5px;
  color: black;
  cursor: pointer;
}

.file-upload .file-help-container {
  display: flex;
  gap: 15px;
}

.file-upload .file-help-container .file-help-text {
  flex: 1;
}
