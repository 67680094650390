.form-input-container {
  position: relative;
}

.form-input-container label {
  color: #545759;
  font-size: 13px;
  line-height: 18px;
}

.form-input {
  height: 37px;
  border-radius: 4px;
  border-color: #d1d1d1;
  font-weight: 300;
}

.form-input::placeholder {
  color: #c8c8c8;
  font-size: 13px;
  line-height: 18px;
}

.form-input-container:not(.with-label) {
  margin: 20px 0;
}

.input-wrapper {
  position: relative;
}

.form-input-icon {
  position: absolute;
  font-size: 17px;
  width: 35px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.form-input-icon.left {
  left: 0px;
}

.form-input-icon.right {
  right: 0px;
}

.form-input-icon.left + input {
  padding-left: 35px;
}

.form-input-icon.right + input {
  padding-right: 35px;
}
