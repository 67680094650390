.sidebar-category {
  margin: 30px 15px;
}

.sidebar-category .sidebar-category-title {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.sidebar-category .sidebar-category-title .sidebar-category__dropdown {
  z-index: 9999;
}

.sidebar-category
  .sidebar-category-title
  .sidebar-category__dropdown
  .dropdown-toggle {
  color: black;
}

.sidebar-category
  .sidebar-category-title
  .sidebar-category__dropdown
  .dropdown-toggle
  .caret {
  display: none;
}

.sidebar-category .sidebar-category__dropdown .sidebar-category__menu-item a {
  padding: 3px 10px;
}

.sidebar-category
  .sidebar-category__dropdown
  .sidebar-category__menu-item
  .sidebar-category__menu-item-icon {
  margin-right: 15px;
}

.sidebar-category .dropdown-toggle .sk-spinner {
  width: 15px;
  height: 15px;
}

.sidebar-category .dropdown-toggle  {
  position: relative;
}

.sidebar-category .dropdown-toggle .tooltip {
  bottom: 123%;
  left: 50%;
  transform: translateX(-50%);
}
