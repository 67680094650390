.company-item__container {
  padding: 3px 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: white;
  justify-content: space-between;
}

.company-item__container > div {
  display: inherit;
  align-items: center;
}

.company-item__container .company-item__suggestion-add {
  color: #4e92df;
  font-size: 15px;
  font-weight: bold;
}

.company-item__container:hover {
  background-color: #e7e7e7;
}

.company-item__container .company-item__avatar-container {
  display: inline-block;
  height: 100%;
  position: relative;
}

.company-item__container .company-item__avatar-container img {
  vertical-align: middle;
}

.company-item__container .company-item__main-content-container {
  display: inline-block;
  margin-left: 15px;
}

.company-item__container .company-item__main-content {
  margin: 2px 0px;
}

.company-item__container
  .company-item__main-content.company-item__company-name {
  font-size: 16px;
}

.company-item__container
  .company-item__main-content.company-item__company-address {
  font-size: 14px;
}

.company-item__container .company-item__main-content.company-item__company-cif {
  font-size: 14px;
}

.company-autocomplete-input {
  width: 100%;
}

.company-autocomplete__menu-container {
  height: 250px;
  max-height: 250px !important;
  overflow: hidden !important;
  padding: 0 !important;
  max-width: 100% !important;
  min-width: 500px !important;
  width: 500px !important;
  background-color: white;
  position: absolute !important;
  left: 0px !important;
  top: 60px !important;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-list-container {
  overflow-y: scroll;
  height: 100%;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-list-container.with-footer {
  height: calc(100% - 50px);
}

.company-autocomplete__menu-container .company-autocomplete__menu-title {
  padding: 10px 0px 10px 20px;
  font-weight: bold;
  font-size: 15px;
}

.company-autocomplete__menu-container .company-autocomplete__menu-footer {
  height: 50px;
  font-size: 18px;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-description {
  background-color: #f7f7f7;
  width: 70%;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-add {
  background-color: #4e92df;
  width: 30%;
  color: white;
  cursor: pointer;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-description,
.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-add {
  text-align: center;
  height: 100%;
  display: inline-block;
}

.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-description
  > span,
.company-autocomplete__menu-container
  .company-autocomplete__menu-footer
  .company-autocomplete__menu-footer-add
  > span {
  line-height: 50px;
  vertical-align: middle;
}

.company-form__separator {
  border-top: 1px solid #e8e8e8;
  margin: 40px 0;
}

.company-form-autocomplete__section-title {
  margin: 10px 20px 5px;
  font-size: 13px;
  font-weight: bold;
}
