.specific-agreement-info__title,
.specific-agreement-info__id {
  font-size: 13px;
  line-height: 18px;
}

.specific-agreement-info__title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #39393a;
}

.specific-agreement-info__id {
  color: #545759;
}
