.dynamic-input-group__container {
  text-align: center;
}

.dynamic-input-group__input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dynamic-input-group__input-container > div {
  flex: 1;
}

.dynamic-input-group__input-container button {
  margin-left: 10px;
  text-align: center;
}
