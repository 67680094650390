.generic-filter-container {
  width: 100%;
}

.generic-filter-container.disabled .generic-filter-select__control {
  background: #eee;
  cursor: not-allowed;
  pointer-events: none;
}

.generic-filter-select__control {
  height: 37px;
  font-size: 15px;
  font-weight: 300;
}

.generic-filter-select__value-container {
  height: 100%;
  overflow-x: auto !important;
  flex-wrap: nowrap !important;
}

.generic-filter-select__control--is-focused {
  border: 1px solid #1ab394 !important;
  box-shadow: none !important;
}

.generic-filter-select__multi-value {
  height: 100%;
  margin: 2px !important;
  background-color: #ddd !important;
  align-items: center;
}

.generic-filter-select__multi-value__tooltip {
  min-width: 0;
}

.generic-filter-select__multi-value__label {
  line-height: initial;
  font-size: 15px;
}

.generic-filter-select__multi-value__remove:hover {
  background-color: #ddd !important;
  color: inherit !important;
}

.generic-filter-select__option {
  line-height: 1.25;
  padding: 6px 15px !important;
  font-size: 15px !important;
  font-weight: normal;
}

.generic-filter-select__placeholder {
  color: #ccc !important;
  font-size: 13px;
}

.generic-filter-select__menu {
  z-index: 99999 !important;
}

.generic-filter-select__menu-notice {
  font-size: 15px !important;
}
