.image-upload {
  margin-bottom: 10px;
}

.image-upload .image-container {
  border: 1px solid #d1d1d1;
  background-color: #fafafa;
  height: 128px;
  width: 128px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.image-upload .remove-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  color: black;
}
