.card-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.card-container .card-content-container {
  margin-left: 10px;
}

.card-container .card-title,
.card-container .card-description {
  font-size: 13px;
}

.card-container .card-title {
  font-size: 13px;
  color: #39393a;
  font-weight: 600;
  line-height: 18px;
}

.card-container .card-description {
  font-size: 13px;
  color: #545759;
  line-height: 16px;
}
