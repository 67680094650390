.agreement-card__container {
  background-color: white;
  padding: 12px 10px;
  display: flex;
  align-items: center;
}

.agreement-card__container .agreement-card__left-container {
  display: flex;
  align-items: center;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__title {
  margin-bottom: 8px;
  color: #545759;
  margin-top: 0;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__company-name {
  font-weight: 500;
  font-size: 14px;
  color: #2c282c;
  vertical-align: middle;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__agreement-id {
  color: #2c282c;
  font-size: 13px;
  line-height: 18px;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__badge {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 12px;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__deadline {
  color: #c61633;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .agreement-card__container .agreement-card__right-container > div {
    float: right;
    clear: both;
  }
}

.agreement-card__container
  .agreement-card__right-container
  .agreement-card__action-container {
  margin-top: 20px;
}

.agreement-card__container
  .agreement-card__right-container
  .agreement-card__request-by-name {
  font-weight: 600;
}

.agreement-card__container .agreement-card__title {
  color: inherit;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__general-agreement-warn {
  margin-left: 10px;
  vertical-align: middle;
  display: inline;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__general-agreement-warn
  button {
  margin-left: 10px;
}

.agreement-card__container
  .agreement-card__left-container
  .agreement-card__general-agreement-warn
  .agreement-card__warn-text {
  margin-left: 5px;
  cursor: pointer;
}

.agreement-card__container
  .agreement-card__general-agreement-warn
  span.clickable {
  cursor: pointer;
  text-decoration: underline;
}
