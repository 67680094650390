.nav-tab__container {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: inherit;
  height: 50px;
}

.nav-tab__container:hover {
  cursor: pointer;
}

.nav-tab__container.selected {
  color: #2d86c2;
  border-bottom-width: 2px;
  border-bottom-color: #2d86c2;
  border-bottom-style: solid;
  padding-top: 2px;
}

.nav-tab__title {
  margin-left: 5px;
}

.nav-tabs__tabs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-tabs__container {
  background-color: #fff;
}
