.logout-button-container {
  position: absolute;
  top: 5px;
  left: 25px;
  z-index: 2;
}

.admin-schools-management-container {
  margin: 20px;
  margin-bottom: 0;
}

.admin-schools-management-container .admin-schools-list-container {
  margin-top: 8px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.admin-schools-management-container .admin-schools-list-table thead {
  width: 100%;
}
